import { store } from '@/store/store';
import { DashCitasPendientes } from '@/shared/dtos/DashCitasPendientes';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'DashCitasPendientesModule',
    store,
    dynamic: true
})
class DashCitasPendientesModule extends VuexModule {
    public DashCitasPendientess: DashCitasPendientes[] = [];

    @Action({ commit: 'onGetDashCitasPendientess' })
    public async getDashCitasPendientess() {
        return await ssmHttpService.get(API.DashCitasPendientes,null,false);
    }

    @Mutation
    public onGetDashCitasPendientess(res: DashCitasPendientes[]) {
        this.DashCitasPendientess = res ? res.map((x) => new DashCitasPendientes(x)) : [];
    }

}
export default getModule(DashCitasPendientesModule);