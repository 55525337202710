import { BaseDto } from './base-dto';

export class DashCitasPendientes extends BaseDto {
    public rojo !: boolean;
    public fecha_cita !: string;
    public nombre !: string;
    public numero_paciente !: number;
    public fecha!:Date;
    public id_cita!:number;

 } 
