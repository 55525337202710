



































import { Component, Vue, Watch } from "vue-property-decorator";
import DashCitasPendientesModule from "@/store/modules/Dashboards/DashCitasPendientes-module";
import VersionBackModule from "@/store/modules/VersionBack-module";
import { UtilsString } from "@/utils/utils-string";
import { LocalStorageService } from "@/shared/services/LocalStorageConfig-service";
import ConfiguracionModule from "@/store/modules/Configuracion-module";
import { Configuracion } from "@/shared/dtos/Configuracion";

@Component({
  components: {
    DisenoMovil: () => import("@/views/home/diseño-movil.vue"),
    DisenoPC: () => import("@/views/home/diseño-pc.vue"),
    NewVersion: () => import("@/views/WhatsIsNew/NewVersion.vue"),
    FacturacionResumida: () =>
      import("@/views/configuracion/ventana-facturacion-resumida.vue"),
  },
})
export default class Home extends Vue {
  public dialogNewVersion: boolean = false;
  public dialogFacturacion: boolean = false;
  public created() {
    DashCitasPendientesModule.getDashCitasPendientess();
    VersionBackModule.getVersionFront().then(() => this.isnewversion());
  }

  get configuracionForBack() {
    return ConfiguracionModule.ConfiguracionForBack;
  }

  @Watch("configuracionForBack")
  onConfiguracionForBackChanged(newVal: Configuracion, oldVal: Configuracion) {
    this.metodoCuandoCambieConfiguracion(newVal, oldVal);
  }

  metodoCuandoCambieConfiguracion(
    newVal: Configuracion,
    oldVal: Configuracion
  ) {
    // Comprobar que haya cambiado bien ConfiguracionForBack

    if (!UtilsString.isEmpty(newVal.id)) {
      if (newVal.id !== oldVal.id) {
        console.log("ConfiguracionForBack ha cambiado:", newVal);

        //en el UserProfile ejecutamos al back sacarle la información de la configuración
        //Vamos a revisar que tenga los datos de facturación correctos.
        // Cuando pase por este modulo de la home
        if (
          UtilsString.IsNullOrWhiteSpace(
            ConfiguracionModule.ConfiguracionForBack.nombre_facturacion
          ) ||
          UtilsString.IsNullOrWhiteSpace(
            ConfiguracionModule.ConfiguracionForBack.direccion_facturacion
          ) ||
          UtilsString.IsNullOrWhiteSpace(
            ConfiguracionModule.ConfiguracionForBack.nif_facturacion
          )
        ) {
          //Ahora enseñamos la ventana de introducción de datos de facturación.
          this.dialogFacturacion = true;
        }
      }
    }
  }

  public mounted() {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
      appLoading.style.display = "none";
    }
  }
  public get buttons() {
    return [
      {
        color: "#ffc107",
        icon: "fad fa-user-friends fa-3x",
        title: "Pacientes",
        namelink: "pacienteslista",
      },
      {
        color: "#ffb74d",
        icon: "fad fa-calendar fa-3x",
        title: "Citas",
        namelink: "calendario",
      },
      {
        color: "#ffab40",
        icon: "fad fa-tachometer-slow fa-3x",
        title: "Seguimiento",
        namelink: "dashboard_empresarial",
      },
      {
        color: "#ff9800",
        icon: "fad fa-cog fa-3x",
        title: "Configuración",
        namelink: "configuracion",
      },
    ];
  }
  public isnewversion() {
    //Estoy en una versión mas antigua?
    VersionBackModule.GetVersionEnv();
    let vlocal = LocalStorageService.getVersion()
      .trim()
      .replace(".", "")
      .replace(".", "");
    let vEnv = VersionBackModule.versionfrontEnv
      .trim()
      .replace(".", "")
      .replace(".", "");
    let vfront = VersionBackModule.versionfront
      .trim()
      .replace(".", "")
      .replace(".", "");
    //si la version de front que devulve el back es mas nueva que la que tiene en cache/entorno
    if (Number.parseInt(vfront) > Number.parseInt(vEnv)) {
      //Pulsamos F5 para refrescar la pagina
      document.location.reload(true);
    }

    if (UtilsString.IsNullOrWhiteSpace(vlocal)) {
      this.dialogNewVersion = false;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
      return false;
    }
    //si la version que tiene en el fichero de entorno es mas nueva que la ultima que ha ejecutado
    if (Number.parseInt(vEnv) > Number.parseInt(vlocal)) {
      this.dialogNewVersion = true;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
    } else {
      this.dialogNewVersion = false;
      LocalStorageService.setVersion(VersionBackModule.versionfrontEnv);
      return false;
    }

    return true;
  }

  public goto(name: string) {
    this.$router.push({ name: name });
  }
  public get NoHayCitas() {
    return DashCitasPendientesModule.DashCitasPendientess.length === 0;
  }
}
